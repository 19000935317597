<template>
  <div class="row patient-profile-dashboard">
    <div
      class="
        col-12 col-md-5 col-xl-4
        text-center
        body-outer-container
        overflow-hidden
      "
      :class="bodyCollapsed ? 'collapsed' : ''"
    >
      <button
        class="
          btn btn-sm btn-theme
          rounded-circle
          d-flex
          align-items-center
          justify-content-center
          collapse-btn
        "
        @click="toggleCollapse"
      >
        <i class="fa fa-lg fa-arrow-left"></i>
      </button>
      <div v-if="gettingNotes && !bodyCollapsed" class="apt-loader pt-5 mt-5">
        <alert class="apt-alert" :emptySlot="true" />
      </div>
      <div class="flip-box" v-if="!updating">
        <div class="flip-box-inner" :class="!isFrontBody && 'flip'">
          <div class="flip-box-front">
            <div id="front-body" class="position-relative w-100">
              <img
                :src="frontImageSrc"
                usemap="#front-image-map"
                alt="Front body"
                class="front-body-img w-100"
              />
              <map
                name="front-image-map"
                @click.prevent="handleSelectedArea($event, 'front')"
              >
                <area
                  v-for="area in frontAreas"
                  :key="area.id"
                  :id="area.id"
                  :alt="area.title"
                  :title="area.title"
                  :coords="area.calculatedCoords"
                  shape="poly"
                />
              </map>

              <div
                v-for="area in frontAreas"
                :id="area.id"
                :key="area.id"
                :style="{ top: area.top, left: area.left }"
                class="
                  badge badge-main-theme
                  font-weight-bold
                  position-absolute
                  pointer-cursor
                "
                @click="openModal(area.id, 'front')"
              >
                <div v-if="area.notesCount > 0">
                  {{ area.notesCount }}
                </div>
              </div>
            </div>
          </div>
          <div class="flip-box-back">
            <div id="back-body" class="position-relative w-100">
              <img
                :src="backImageSrc"
                usemap="#back-image-map"
                alt="Back Body"
                class="back-body-img w-100"
              />
              <map
                name="back-image-map"
                @click.prevent="handleSelectedArea($event, 'back')"
              >
                <area
                  v-for="area in backAreas"
                  :key="area.id"
                  :id="area.id"
                  :alt="area.title"
                  :title="area.title"
                  :coords="area.calculatedCoords"
                  shape="poly"
                />
              </map>
              <div
                v-for="area in backAreas"
                :id="area.id"
                :key="area.id"
                :style="{ top: area.top, left: area.left }"
                class="
                  btn
                  badge badge-main-theme
                  font-weight-bold
                  position-absolute
                  pointer-cursor
                "
                @click="openModal(area.id, 'back')"
              >
                <div v-if="area.notesCount > 0">
                  {{ area.notesCount }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        v-if="!bodyCollapsed"
        class="btn btn-secondary px-4 rounded-pill my-2"
        @click="toggleBody"
      >
        <i class="fas fa-arrow-left"></i>
        <i class="fas fa-arrow-right"></i>
      </button>
      <button
        class="btn btn-theme px-4 rounded-pill m-2"
        v-if="!bodyCollapsed"
        @click="toggleBodyType"
      >
        <i v-if="isMale" class="fas fa-lg fa-male"></i>
        <i v-if="!isMale" class="fas fa-lg fa-female"></i>
      </button>
      <button
        v-if="!bodyCollapsed"
        class="btn m-2 btn-transparent"
        @click="enlargeBody"
      >
        <i class="fas fa-lg fa-expand-arrows-alt"></i>
      </button>
    </div>
    <div
      class="col-12 px-0 d-flex"
      :class="bodyCollapsed ? 'w-100-36' : 'col-md-7 col-xl-8'"
    >
      <div class="d-block w-100">
        <div
          class="w-100 d-flex flex-wrap radius-36 right-section light-shadow"
        >
          <div class="col-12 col-xl-6 mt-3">
            <div
              class="
                card-container
                small-card
                bg-table
                text-white
                px-3
                mr-2
                mb-2
              "
            >
              <div class="d-flex align-items-center mb-2">
                <i class="card-icon mr-3 mb-0 fas fa-lg fa-chart-pie"></i>
                <p class="text-capitalize title">Average Mood Rating</p>
              </div>
              <div class="text-center" v-if="!showCharts">
                <i class="fas fa-lg fa-spin fa-circle-notch mr-5"></i>
              </div>
              <p v-else class="value text-center">
                {{ averageMoodRate }}
              </p>
            </div>
            <div
              class="
                card-container
                small-card
                bg-table
                text-white
                px-3
                mr-2
                mb-2
              "
            >
              <div class="d-flex align-items-center mb-2">
                <img
                  class="card-icon mr-3 mb-0 large"
                  src="@/assets/img/dashboard/sessions-done.svg"
                />
                <p class="text-capitalize title">Sessions completed</p>
              </div>
              <div class="text-center" v-if="gettingStatistics">
                <i class="fas fa-lg fa-spin fa-circle-notch mr-5"></i>
              </div>
              <p v-else class="value text-center">
                {{ statistics?.completed_sessions }}
              </p>
            </div>
            <div
              class="
                card-container
                bg-lightgray
                text-black
                px-3
                mr-2
                mb-2
                d-flex
                flex-column
              "
            >
              <div class="d-flex align-items-center mb-2">
                <i class="card-icon mr-3 mb-0 far fa-lg fa-calendar-check"></i>
                <p class="text-capitalize title">Last Session</p>
              </div>
              <div class="text-center" v-if="gettingStatistics">
                <i class="fas fa-lg fa-spin fa-circle-notch mr-5"></i>
              </div>
              <p v-else class="value text-center">
                {{ prettyUTCDate(statistics?.last_session_time) || "N/A" }}
              </p>

              <div class="d-flex align-items-center mb-2 mt-3">
                <i class="card-icon mr-3 mb-0 far fa-lg fa-calendar-alt"></i>
                <p class="text-capitalize title">Next Session</p>
              </div>
              <div class="text-center" v-if="gettingStatistics">
                <i class="fas fa-lg fa-spin fa-circle-notch mr-5"></i>
              </div>
              <p v-else class="value text-center">
                {{ prettyUTCDate(statistics?.next_session_time) || "N/A" }}
              </p>
            </div>
          </div>
          <div class="col-12 col-xl-6 mt-3">
            <div
              class="
                col-12
                card-container
                bg-lightgray
                text-black
                px-0
                pb-0
                pt-3
                overflow-hidden
                chart-container
              "
            >
              <div v-if="!showCharts" class="apt-loader mt-5">
                <alert class="apt-alert" :emptySlot="true" />
              </div>
              <div id="sleepChart"></div>
            </div>
            <div
              class="
                col-12
                card-container
                bg-lightgray
                text-black
                px-0
                pb-0
                pt-3
                overflow-hidden
                chart-container
              "
            >
              <div v-if="!showCharts" class="apt-loader mt-5">
                <alert class="apt-alert" :emptySlot="true" />
              </div>
              <div id="fatigueChart"></div>
            </div>
          </div>
          <div class="col-12 col-xl-6" v-if="user && !user.isPatient">
            <div
              class="
                card-container
                bg-table
                text-black
                px-0
                mb-2
                pb-0
                pt-3
                overflow-hidden
                chart-container
              "
              style="max-width: 100%"
              :style="
                generalSymptomsObservedChartEmpty
                  ? 'min-height: calc(100% - 13px)'
                  : ''
              "
            >
              <div v-if="!showCharts" class="apt-loader mt-5">
                <alert class="apt-alert" :emptySlot="true" />
              </div>
              <div
                id="generalSymptomsObservedChart"
                v-show="!generalSymptomsObservedChartEmpty"
              ></div>
              <alert
                class="bg-transparent mb-3 text-white border-0"
                :hideLoader="true"
                v-show="generalSymptomsObservedChartEmpty"
              >
                No general symptoms observed
              </alert>
            </div>
          </div>
          <div class="col-12 col-xl-6" v-if="user && !user.isPatient">
            <div
              class="
                card-container
                bg-lightgray
                text-black
                px-3
                mb-2
                pb-0
                pt-3
                overflow-hidden
                chart-container
                d-flex
                flex-column
                align-items-center
                justify-content-center
              "
              :class="`${
                !gettingLastIntake && !intake
                  ? 'justify-content-start'
                  : 'justify-content-center'
              }`"
              style="height: calc(100% - 13px)"
            >
              <h2 class="text-left w-100 mb-2 pt-2">Client History Notes</h2>
              <div v-if="gettingLastIntake" class="apt-loader mt-5">
                <alert class="apt-alert" :emptySlot="true" />
              </div>
              <div v-if="intake" class="w-100">
                <div
                  class="w-100 mb-auto overflow-hidden"
                  style="height: 156px"
                >
                  <intake-viewer
                    :intake="intake"
                    @change="updateSelectedIntakeFormInfo($event)"
                  ></intake-viewer>
                </div>
                <div class="w-100 d-flex justify-content-center">
                  <button
                    class="btn btn-sm btn-black text-main my-3"
                    @click="viewIntakeFormInfo()"
                  >
                    Show Full Report
                  </button>
                </div>
              </div>
              <alert
                class="bg-transparent mt-5 mb-3 text-black border-0"
                :hideLoader="true"
                v-show="!gettingLastIntake && !intake"
              >
                No completed intakes found
              </alert>
            </div>
          </div>
          <div class="col-12" v-if="user && !user.isPatient">
            <div
              class="
                card-container
                bg-lightgray
                text-black
                px-3
                mb-2
                pb-0
                pt-3
                overflow-visible
                chart-container
                d-flex
                flex-column
                align-items-center
                justify-content-center
              "
              :class="`${
                !gettingLastIntake && !intake
                  ? 'justify-content-start'
                  : 'justify-content-center'
              }`"
              style="height: calc(100% - 13px)"
            >
              <h2 class="text-left w-100 mb-2 pt-2">Client Engagement Notes</h2>
              <div v-if="gettingLastIntake" class="apt-loader mt-5">
                <alert class="apt-alert" :emptySlot="true" />
              </div>
              <div v-if="intake" class="w-100">
                <div class="w-100 mb-auto overflow-visible">
                  <intake-viewer
                    :intake="intake"
                    :hideMainReport="true"
                    @change="updateSelectedIntakeFormInfo($event)"
                  ></intake-viewer>
                </div>
              </div>
              <alert
                class="bg-transparent mt-5 mb-3 text-black border-0"
                :hideLoader="true"
                v-show="!gettingLastIntake && !intake"
              >
                No client engagement notes found
              </alert>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      name="notes-body-modal"
      transition="pop-out"
      class="notes-body-modal"
      :width="450"
      :focus-trap="true"
      :height="'auto'"
    >
      <div class="container position-relative px-0" v-if="selectedArea">
        <div
          class="
            modal-title
            border-bottom
            position-relative
            p-3
            mb-2
            font-weight-bold
          "
        >
          {{ selectedArea.title }}
          <button class="close text-dark mr-3" @click="closeModal">
            <i class="fas fa-times"></i>
          </button>
        </div>

        <div class="notes-container p-3">
          <ul class="notes-list p-0">
            <li
              class="note-container"
              v-for="note in selectedArea.notes"
              :key="note.id"
            >
              <div
                class="
                  note-info
                  d-flex
                  justify-content-between
                  align-items-center
                  px-3
                  py-1
                "
              >
                <p class="mb-0 font-weight-bold">{{ note.author }}</p>
                <p class="mb-0 small text-semi-muted">
                  {{ prettyDate(note.created_at) }}
                </p>
              </div>
              <hr class="m-0" />
              <div
                class="
                  note-message
                  d-flex
                  align-items-start
                  justify-content-start
                  my-3 my-md-4
                "
              >
                <span v-if="!editingNote || editingNote.id !== note.id">{{
                  note.note
                }}</span>
                <input-control
                  v-model="editNoteContent"
                  control="textarea"
                  wrapClass="m-0 w-100"
                  inputClass="p-0"
                  labelClass="d-none"
                  v-if="editingNote && editingNote.id == note.id"
                  >note</input-control
                >
              </div>
              <hr class="m-0" />
              <div class="d-flex justify-content-end py-2 px-3">
                <button
                  class="btn btn-theme btn-sm mr-2"
                  v-if="
                    user &&
                    (user.id == note.author_id ||
                      user.isAdmin ||
                      user.isManagingAdmin) &&
                    (!editingNote || editingNote.id !== note.id)
                  "
                  @click="editNote(note)"
                >
                  Edit
                </button>
                <button
                  class="btn btn-link btn-sm mr-2"
                  v-if="editingNote && editingNote.id == note.id"
                  @click="editingNote = null"
                >
                  Cancel
                </button>
                <button
                  class="btn btn-deep-success btn-sm mr-2"
                  v-if="editingNote && editingNote.id == note.id"
                  @click="updateNote(note)"
                >
                  Save
                </button>
                <button class="btn btn-danger btn-sm" @click="deleteNote(note)">
                  Delete
                </button>
              </div>
            </li>

            <li class="note-container mt-3">
              <div
                class="
                  note-info
                  d-flex
                  justify-content-between
                  align-items-center
                  px-3
                  py-1
                "
              >
                <p class="mb-0 font-weight-bold">New Note</p>
                <p class="mb-0 small text-semi-muted">Now</p>
              </div>
              <hr class="m-0" />
              <div
                class="
                  note-message
                  d-flex
                  align-items-start
                  justify-content-start
                  my-3 my-md-4
                "
              >
                <input-control
                  v-model="newNoteContent"
                  control="textarea"
                  wrapClass="m-0 w-100"
                  inputClass="p-0"
                  labelClass="d-none"
                  >note</input-control
                >
              </div>
              <hr class="m-0" />
              <div class="d-flex justify-content-end py-2 px-3">
                <button
                  class="btn btn-theme btn-sm"
                  @click="addNewNote(selectedArea.id)"
                >
                  Add Note
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </modal>
    <modal
      name="large-body-modal"
      transition="pop-out"
      class="large-body-modal"
      :width="'100%'"
      :height="'100%'"
      @before-close="closeLargeModal"
    >
      <div class="modal-container">
        <button class="close close-btn text-black" @click="hideLargeModal">
          <i class="fas fa-times" />
        </button>
        <div
          class="flip-box"
          v-if="!updating"
          :style="{ maxWidth: `${bodyZoomLevel}%` }"
        >
          <div class="flip-box-inner" :class="!isFrontBody && 'flip'">
            <div class="flip-box-front w-100">
              <div id="large-front-body" class="position-relative">
                <img
                  :src="frontImageSrc"
                  usemap="#large-front-image-map"
                  alt="Front body"
                  class="large-front-body-img w-100"
                />
                <map
                  name="large-front-image-map"
                  @click.prevent="handleSelectedArea($event, 'front')"
                >
                  <area
                    v-for="area in frontAreas"
                    :key="area.id"
                    :id="area.id"
                    :alt="area.title"
                    :title="area.title"
                    :coords="area.calculatedCoords"
                    shape="poly"
                  />
                </map>

                <div
                  v-for="area in frontAreas"
                  :id="area.id"
                  :key="area.id"
                  :style="{ top: area.top, left: area.left }"
                  class="
                    badge badge-main-theme
                    font-weight-bold
                    position-absolute
                    pointer-cursor
                  "
                  @click="openModal(area.id, 'front')"
                >
                  <div v-if="area.notesCount > 0">
                    {{ area.notesCount }}
                  </div>
                </div>
              </div>
            </div>
            <div class="flip-box-back w-100">
              <div id="large-back-body" class="position-relative w-100">
                <img
                  :src="backImageSrc"
                  usemap="#large-back-image-map"
                  alt="Back Body"
                  class="large-back-body-img w-100"
                />
                <map
                  name="large-back-image-map"
                  @click.prevent="handleSelectedArea($event, 'back')"
                >
                  <area
                    v-for="area in backAreas"
                    :key="area.id"
                    :id="area.id"
                    :alt="area.title"
                    :title="area.title"
                    :coords="area.calculatedCoords"
                    shape="poly"
                  />
                </map>
                <div
                  v-for="area in backAreas"
                  :id="area.id"
                  :key="area.id"
                  :style="{ top: area.top, left: area.left }"
                  class="
                    btn
                    badge badge-main-theme
                    font-weight-bold
                    position-absolute
                    pointer-cursor
                  "
                  @click="openModal(area.id, 'back')"
                >
                  <div v-if="area.notesCount > 0">
                    {{ area.notesCount }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="zoom-actions">
        <button
          class="btn btn-transparent"
          @click="zoomBody(1)"
          :disabled="bodyZoomLevel >= 300"
        >
          <i class="fas fa-search-plus"></i>
        </button>
        <button
          class="btn btn-transparent mt-2"
          @click="zoomBody(-1)"
          :disabled="bodyZoomLevel <= 20"
        >
          <i class="fas fa-search-minus"></i>
        </button>
      </div>
      <div class="modal-actions">
        <button class="btn btn-secondary px-4 my-2" @click="toggleBody">
          <i class="fas fa-arrow-left"></i>
          <i class="fas fa-arrow-right"></i>
        </button>
        <button class="btn btn-theme px-5 mx-5" @click="hideLargeModal()">
          Close
        </button>
        <button
          class="btn btn-secondary px-4 m-2"
          @click="toggleBodyType(false)"
        >
          <i v-if="isMale" class="fas fa-lg fa-male"></i>
          <i v-if="!isMale" class="fas fa-lg fa-female"></i>
        </button>
      </div>
    </modal>
    <modal
      class="intake-form-info-modal"
      name="intake-form-info-modal"
      transition="pop-out"
      width="96%"
      height="96%"
    >
      <div class="modal-container">
        <div
          class="modal-title border-bottom text-secondary position-relative p-3"
        >
          Intake Form Report
          <button class="close pr-2" @click="closeIntakeFormInfoModal">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <div class="details-container card p-3">
            <intake-viewer
              :intake="intake"
              @change="updateSelectedIntakeFormInfo($event)"
            ></intake-viewer>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2";
import {
  FEMALE_FRONT_AREAS,
  FEMALE_BACK_AREAS,
  MALE_FRONT_AREAS,
  MALE_BACK_AREAS,
  SPARK3,
  BAR_HORIZONTAL,
} from "./constants";
import { debounce, cloneDeep } from "lodash";
import helpers from "@/utils/helpers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import ApexCharts from "apexcharts";
import QUESTIONS from "../../../../store/patients/constants";
import IntakeViewer from "../intakeForms/Viewer.vue";

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: "patient.dashboard",
  components: { IntakeViewer },
  data() {
    return {
      isFrontBody: true,
      bodyCollapsed: true,
      gettingStatistics: true,
      gettingNotes: true,
      statistics: {},
      frontAreas: [],
      backAreas: [],
      bodyNotes: [],
      selectedArea: null,
      isMale: null,
      newNoteContent: "",
      editingNote: null,
      editNoteContent: "",
      updating: false,
      gettingLastIntake: false,
      intake: null,
      largeModalopened: false,
      bodyZoomLevel: 80,
      chartsRef: {
        fatigueChart: null,
        sleepChart: null,
        generalSymptomsObservedChart: null,
      },
      generalSymptomsObservedChartEmpty: false,
      sleepChart: SPARK3,
      fatigueChart: SPARK3,
      generalSymptomsObservedChart: BAR_HORIZONTAL,
      showCharts: false,
      averageMoodRate: "N/A",
      generalSymptomsObservedCategories: QUESTIONS.GSO_QUESTIONS,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      patient: (state) => state.patients.patient,
      questionnaires: (state) => state.patients.symptoms.questionnaire.data,
      GSOQuestions: (state) => state.patients.symptoms.GSOQuestions,
    }),
    frontImageSrc() {
      return this.isMale
        ? require("@/assets/img/patient/male-front-body.png")
        : require("@/assets/img/patient/female-front-body.png");
    },
    backImageSrc() {
      return this.isMale
        ? require("@/assets/img/patient/male-back-body.png")
        : require("@/assets/img/patient/female-back-body.png");
    },
    imageScale() {
      return this.isMale ? 1684 : 1484;
    },
  },
  methods: {
    ...mapActions({
      getBodyNotes: "patients/dashboard/getBodyNotes",
      addBodyNote: "patients/dashboard/addBodyNote",
      updateBodyNote: "patients/dashboard/updateBodyNote",
      deleteBodyNote: "patients/dashboard/deleteBodyNote",
      getStatistics: "patients/dashboard/getStatistics",
      getQuestionnaires: "patients/symptoms/getAllQuestionnaires",
      getLastIntake: "intakeForms/getLastCompletedIntake",
    }),
    formattedDate(dateToformat, flag = false) {
      if (dateToformat && dateToformat != "") {
        return dayjs(dateToformat)
          .add(-1 * dayjs(dateToformat).utcOffset(), "minute")
          .format(!flag ? "YYYY-MM-DD hh:mm A" : "MMM-DD hh:mm A");
      } else return "";
    },
    closeIntakeFormInfoModal() {
      this.$modal.hide("intake-form-info-modal");
    },
    viewIntakeFormInfo() {
      this.$modal.show("intake-form-info-modal");
    },
    prettyDate(date) {
      if (!date) {
        return "";
      }
      return helpers.prettyDate(date);
    },
    prettyUTCDate(date) {
      if (!date) {
        return "";
      }
      return dayjs(date).utc().format("MMM D, YYYY hh:mm A");
    },
    toggleCollapse() {
      this.bodyCollapsed = !this.bodyCollapsed;
      this.toggleBodyType(true);
    },
    toggleBody() {
      this.isFrontBody = !this.isFrontBody;
      setTimeout(() => {
        this.recalculateMapCoords();
      }, 0);
    },
    toggleBodyType(noBodyChange) {
      this.updating = true;
      setTimeout(() => {
        if (!noBodyChange) {
          this.isMale = !this.isMale;
        }
        setTimeout(() => {
          this.updating = false;
          this.initBodyMap();
          setTimeout(() => {
            this.recalculateMapCoords();
          }, 300);
        });
      }, 0);
    },
    addNoteToArea(id, user_id, part_id, author_id, note, author, created_at) {
      const areas = this.frontAreas.some((part) => part.id == part_id)
        ? this.frontAreas
        : this.backAreas;
      const area = areas.find((area) => area.id == part_id);

      if (!area) {
        return;
      }

      if (area.top == 0 && area.left == 0 && area.calculatedCoords) {
        const coords = area.calculatedCoords.split(",").map(Number);
        const xCoords = coords.filter((_, i) => i % 2 == 0);
        const yCoords = coords.filter((_, i) => i % 2 !== 0);
        const centroidX = xCoords.reduce((a, b) => a + b, 0) / xCoords.length;
        const centroidY = yCoords.reduce((a, b) => a + b, 0) / yCoords.length;

        area.top = `${centroidY}px`;
        area.left = `${centroidX}px`;
      }

      area.notesCount += 1;
      area.notes.push({
        id,
        user_id,
        part_id,
        author_id,
        note,
        author,
        created_at,
      });
    },
    updateExistNote(id, part_id, author_id, note, author) {
      const areas = this.frontAreas.some((part) => part.id == part_id)
        ? this.frontAreas
        : this.backAreas;
      const area = areas.find((area) => area.id == part_id);
      if (!area) {
        return;
      }
      const existNote = area.notes.find((note) => note.id == id);
      if (!existNote) {
        return;
      }

      existNote.note = note;
      existNote.author = author;
      existNote.author_id = author_id;
    },
    deleteExistNote(id, part_id) {
      const areas = this.frontAreas.some((part) => part.id == part_id)
        ? this.frontAreas
        : this.backAreas;
      const area = areas.find((area) => area.id == part_id);
      if (!area) return;

      const noteIndex = area.notes.findIndex((note) => note.id == id);
      if (noteIndex !== -1) {
        area.notes.splice(noteIndex, 1);
        area.notesCount -= 1;
      }
    },
    editNote(note) {
      this.editingNote = note;
      this.editNoteContent = note.note;
    },
    openModal(areaId, side) {
      const areas = side == "front" ? this.frontAreas : this.backAreas;
      this.selectedArea = areas.find((area) => area.id == areaId);
      this.$modal.show("notes-body-modal");
    },
    closeModal() {
      this.editingNote = null;
      this.$modal.hide("notes-body-modal");
    },
    addNewNote(part_id) {
      if (!this.newNoteContent) {
        return;
      }

      const newNote = {
        part_id: part_id,
        user_id: this.patient.user_id,
        note: this.newNoteContent,
      };

      this.addBodyNote(newNote).then((data) => {
        if (data) {
          this.addNoteToArea(
            data.id,
            data.user_id,
            data.part_id,
            data.author_id,
            data.note,
            data.author,
            data.created_at
          );
          this.newNoteContent = "";
          Swal.fire({
            title: "",
            text: "Note Added Successfully",
            icon: "success",
          });
        } else {
          Swal.fire({
            title: "",
            text: "Failed to add note",
            icon: "error",
          });
        }
      });
    },
    updateNote(note) {
      if (!this.editingNote) {
        return;
      }
      if (this.editNoteContent == "") {
        Swal.fire({
          title: "",
          text: "Please fill the note field",
          icon: "error",
        });
        return;
      }

      this.updateBodyNote({ ...note, note: this.editNoteContent }).then(
        (data) => {
          if (data !== false) {
            this.updateExistNote(
              data.id,
              data.part_id,
              data.author_id,
              data.note,
              data.author
            );
            this.editingNote = null;
            note.note = this.editNoteContent;
            Swal.fire({
              title: "",
              text: "Note Updated Successfully",
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "",
              text: "Failed to update note",
              icon: "error",
            });
          }
        }
      );
    },
    deleteNote(note) {
      this.deleteBodyNote(note).then((data) => {
        if (data !== false) {
          this.deleteExistNote(note.id, note.part_id);
          Swal.fire({
            title: "",
            text: "Note Deleted Successfully",
            icon: "success",
          });
        } else {
          Swal.fire({
            title: "",
            text: "Failed to delete note",
            icon: "error",
          });
        }
      });
    },
    handleSelectedArea(event, side) {
      const triggerId = event.target.id;
      this.openModal(triggerId, side);
    },
    recalculateMapCoords() {
      const preSelector = this.largeModalopened ? "large-" : "";
      window.$(`.${preSelector}front-body-img`).off(".maphilight");
      window.$(`.${preSelector}back-body-img`).off(".maphilight");
      window.$(`.${preSelector}front-body-img`).maphilight({ alwaysOn: false });
      window.$(`.${preSelector}back-body-img`).maphilight({ alwaysOn: false });
      window.$(`.${preSelector}front-body-img`).removeAttr("style");
      window.$(`.${preSelector}back-body-img`).removeAttr("style");
      if (this.isFrontBody) {
        const scaleFactor =
          window.$(`.${preSelector}front-body-img`).width() / this.imageScale;
        this.frontAreas.forEach((area) => {
          const coords = area.coords.split(",");
          const newCoords = [];
          coords.forEach((item) => {
            newCoords.push(item * scaleFactor);
          });
          area.calculatedCoords = newCoords.join(",");
          const xCoords = newCoords.filter((_, i) => i % 2 == 0);
          const yCoords = newCoords.filter((_, i) => i % 2 !== 0);
          const centroidX = xCoords.reduce((a, b) => a + b, 0) / xCoords.length;
          const centroidY = yCoords.reduce((a, b) => a + b, 0) / yCoords.length;

          area.top = `${centroidY}px`;
          area.left = `${centroidX}px`;
        });
        window.$(`.${preSelector}front-body-img`).maphilight({ stroke: false });
      } else {
        const scaleFactor =
          window.$(`.${preSelector}back-body-img`).width() / this.imageScale;
        this.backAreas.forEach((area) => {
          const coords = area.coords.split(",");
          const newCoords = [];
          coords.forEach((item) => {
            newCoords.push(item * scaleFactor);
          });
          area.calculatedCoords = newCoords.join(",");
          const xCoords = newCoords.filter((_, i) => i % 2 == 0);
          const yCoords = newCoords.filter((_, i) => i % 2 !== 0);
          const centroidX = xCoords.reduce((a, b) => a + b, 0) / xCoords.length;
          const centroidY = yCoords.reduce((a, b) => a + b, 0) / yCoords.length;

          area.top = `${centroidY}px`;
          area.left = `${centroidX}px`;
        });
        window.$(`.${preSelector}back-body-img`).maphilight({ stroke: false });
      }
      this.$forceUpdate();
    },
    initBodyMap() {
      this.frontAreas = JSON.parse(
        JSON.stringify(this.isMale ? MALE_FRONT_AREAS : FEMALE_FRONT_AREAS)
      );
      this.backAreas = JSON.parse(
        JSON.stringify(this.isMale ? MALE_BACK_AREAS : FEMALE_BACK_AREAS)
      );
      this.recalculateMapCoords();
      if (this.gettingNotes) {
        this.getBodyNotes(this.patient.user_id).then((data) => {
          this.bodyNotes = data;
          data &&
            data.forEach((noteData) => {
              this.addNoteToArea(
                noteData.id,
                noteData.user_id,
                noteData.part_id,
                noteData.author_id,
                noteData.note,
                noteData.author,
                noteData.created_at
              );
            });
          this.gettingNotes = false;
        });
      } else {
        this.bodyNotes &&
          this.bodyNotes.forEach((noteData) => {
            this.addNoteToArea(
              noteData.id,
              noteData.user_id,
              noteData.part_id,
              noteData.author_id,
              noteData.note,
              noteData.author,
              noteData.created_at
            );
          });
      }
    },
    getSleepChart() {
      if (this.chartsRef.sleepChart) {
        this.chartsRef.sleepChart.destroy();
      }
      const options = cloneDeep(this.sleepChart);
      const data = [];
      const labels = [];
      for (let questionnaire of this.questionnaires) {
        if (questionnaire.hours_client || questionnaire.hours_client === 0) {
          data.push(Number(questionnaire.hours_client));
          labels.push(`${this.formattedDate(questionnaire.apt_start_time)}`);
        }
      }

      const sum = data.reduce((a, b) => a + b, 0);
      const avg = sum / data.length || 0;

      options.series[0].data = data;
      options.labels = labels;
      options.series[0].name = "Sleep";
      options.title.text = "Sleep (hrs/night)";
      options.subtitle.text = `Average: ${+parseFloat(avg).toFixed(2)} Hours`;

      if (document.querySelector("#sleepChart")) {
        this.chartsRef.sleepChart = new ApexCharts(
          document.querySelector("#sleepChart"),
          options
        );
        this.chartsRef.sleepChart.render();
      }
    },
    getFatigueChart() {
      if (this.chartsRef.fatigueChart) {
        this.chartsRef.fatigueChart.destroy();
      }
      const options = cloneDeep(this.fatigueChart);
      const data = [];
      const labels = [];
      for (let questionnaire of this.questionnaires) {
        if (
          (questionnaire.fatigue_rate_client &&
            questionnaire.fatigue_rate_client != -1) ||
          questionnaire.fatigue_rate_client === 0
        ) {
          data.push(Number(questionnaire.fatigue_rate_client));
          labels.push(`${this.formattedDate(questionnaire.apt_start_time)}`);
        }
      }

      const sum = data.reduce((a, b) => a + b, 0);
      const avg = sum / data.length || 0;

      options.series[0].data = data;
      options.labels = labels;
      options.series[0].name = "Fatigue Level";
      options.title.text = "Fatigue Level";
      options.subtitle.text = `Average: ${+parseFloat(avg).toFixed(2)}/10`;

      if (document.querySelector("#fatigueChart")) {
        this.chartsRef.fatigueChart = new ApexCharts(
          document.querySelector("#fatigueChart"),
          options
        );
        this.chartsRef.fatigueChart.render();
      }
    },
    getGeneralSymptomsObservedChart() {
      if (this.chartsRef.generalSymptomsObservedChart) {
        this.chartsRef.generalSymptomsObservedChart.destroy();
      }
      const options = cloneDeep(this.generalSymptomsObservedChart);
      const horizontalSeries = [];
      const data = [];
      const labels = [];
      const horizontalCategories = this.generalSymptomsObservedCategories;
      const questionnaires = this.questionnaires;
      if (questionnaires.length) {
        this.GSOQuestions.forEach((category, index) => {
          const yValue = this.getPercentFor(category.key, questionnaires);
          if (yValue) {
            data.push(yValue);
            labels.push(horizontalCategories[index].keyword);
            horizontalSeries.push({
              y: yValue,
              x: horizontalCategories[index].keyword,
              label: horizontalCategories[index].text,
            });
          }
        });
      }
      options.series[0].data = horizontalSeries.slice(0, 4);
      // options.labels = labels;
      this.generalSymptomsObservedChartEmpty = !data.length;

      if (document.querySelector("#generalSymptomsObservedChart")) {
        this.chartsRef.generalSymptomsObservedChart = new ApexCharts(
          document.querySelector("#generalSymptomsObservedChart"),
          options
        );
        this.chartsRef.generalSymptomsObservedChart.render();
      }
    },
    getMoodRate() {
      const data = [];
      for (let questionnaire of this.questionnaires) {
        if (
          (questionnaire.mood_rate_client &&
            questionnaire.mood_rate_client != -1) ||
          questionnaire.mood_rate_client === 0
        ) {
          data.push(Number(questionnaire.mood_rate_client));
        }
      }

      const sum = data.reduce((a, b) => a + b, 0);
      const avg = sum / data.length || 0;
      if (data.length) {
        this.averageMoodRate = `${+parseFloat(avg).toFixed(2)}/10`;
      } else {
        this.averageMoodRate = "N/A";
      }
    },
    getPercentFor(generalSymptomsCategory, cleanedQuestionnaires) {
      const countOfYes = cleanedQuestionnaires.filter(
        (questionnaire) =>
          parseInt(questionnaire[generalSymptomsCategory] || "0") === 1
      ).length;
      return (countOfYes / cleanedQuestionnaires.length) * 100;
    },
    enlargeBody() {
      this.bodyZoomLevel = 80;
      this.$modal.show("large-body-modal");
      this.largeModalopened = true;
      setTimeout(() => {
        this.initBodyMap();
      }, 100);
    },
    hideLargeModal() {
      this.$modal.hide("large-body-modal");
    },
    closeLargeModal() {
      this.largeModalopened = false;
      setTimeout(() => {
        this.initBodyMap();
      }, 100);
    },
    zoomBody(direction) {
      let zoomLevel = this.bodyZoomLevel;
      zoomLevel = zoomLevel + direction * 20;
      if (zoomLevel < 20) {
        zoomLevel = 20;
      }
      if (zoomLevel > 300) {
        zoomLevel = 300;
      }
      this.bodyZoomLevel = zoomLevel;
      this.$forceUpdate();

      setTimeout(() => {
        this.initBodyMap();
      }, 310);
    },
    updateSelectedIntakeFormInfo(data) {
      this.intake = data;
    },
  },
  created() {
    window.addEventListener("resize", debounce(this.recalculateMapCoords, 500));
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.recalculateMapCoords);
    this.chartsRef.fatigueChart && this.chartsRef.fatigueChart.destroy();
    this.chartsRef.sleepChart && this.chartsRef.sleepChart.destroy();
    this.chartsRef.generalSymptomsObservedChart &&
      this.chartsRef.generalSymptomsObservedChart.destroy();
  },
  mounted() {
    this.isMale = this.patient.gender != 2;
    this.initBodyMap();
    this.getStatistics(this.patient.id).then((res) => {
      if (res) {
        this.statistics = res;
      }
      this.gettingStatistics = false;
    });

    this.getQuestionnaires(this.patient.user_id).then(() => {
      this.showCharts = true;
      this.getFatigueChart();
      this.getSleepChart();
      this.getMoodRate();
      if (!this.user.isPatient) {
        this.getGeneralSymptomsObservedChart();
      }
    });
    this.gettingLastIntake = true;
    this.getLastIntake(this.patient.user_id).then((data) => {
      this.gettingLastIntake = false;
      if (data) {
        this.intake = data;
        if (this.intake.report) {
          if (this.patient.diagnosis) {
            this.intake.report = {
              ...this.intake.report,
              Diagnosis: this.patient.diagnosis,
            };
          }
          if (this.patient.assessment_active) {
            this.intake.report = {
              ...this.intake.report,
              Assessment: this.patient.assessment,
            };
          }
          if (this.patient.treatment_active) {
            this.intake.report = {
              ...this.intake.report,
              Treatment: this.patient.treatment,
            };
          }
          if (this.patient.intervention_active) {
            this.intake.report = {
              ...this.intake.report,
              Intervention: this.patient.intervention,
            };
          }
        }
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.apt-loader {
  align-items: flex-start;
  z-index: 1;

  .apt-alert {
    border: 0;
  }
}
.right-section {
  background-color: rgba(255, 255, 255, 0.3);
  transition: all 0.3s ease-in-out;
}

.collapsed {
  width: 36px;
  max-width: 36px;
  flex: 0 0 36px;
  overflow: hidden;
  padding: 0;

  .flip-box-inner {
    .badge {
      display: none;
    }
  }

  .collapse-btn {
    transform: rotate(180deg);
    right: 2px;
  }
}
.collapse-btn {
  width: 32px;
  height: 32px;
  min-width: 32px;
  transition: all 0.3s ease-in-out;
  position: absolute;
  right: 24px;
  top: 4px;
  z-index: 1;
}
.w-100-36 {
  width: calc(100% - 36px);
  max-width: calc(100% - 36px);
}
.flip-box {
  perspective: 1000px;
}

.body-outer-container {
  transition: all 0.3s ease-in-out;
}

.flip-box-inner {
  position: relative;
  transition: transform 1.8s;
  transform-style: preserve-3d;

  &.flip {
    transform: rotateY(180deg);
  }
}

.flip-box-front,
.flip-box-back {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-back {
  position: absolute;
  top: 0;
  transform: rotateY(180deg);
}

.front-body-img,
.back-body-img {
  height: auto !important;
  position: static !important;
}

.bg-lightgray {
  background-color: rgba(160, 155, 155, 0.2);
}

.chart-container {
  min-height: 172px;
  position: relative;
}
.card-container {
  display: inline-block;
  padding: 24px;
  border-radius: 36px;
  width: 100%;

  &.small-card {
    padding-top: 13px;
    padding-bottom: 13px;
  }
  .title {
    margin-bottom: 4px;
    font-size: 17px;
  }
  .value {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.1;
    letter-spacing: 0.2px;

    &.large {
      font-size: 32px;
    }
  }
}
.intake-form-info-modal {
  .v--modal {
    max-width: 96%;
  }
  .modal-title {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 26px;
    background-color: rgba(126, 126, 126, 0.1);
  }
  .close {
    color: black !important;
    font-size: 1.5em;
  }
  .modal-body {
    background-color: var(--body-bg-color);
    padding: 24px;
    height: calc(100% - 63px);
    overflow: auto;
    position: absolute;
    top: 63px;
    left: 0;
    right: 0;
  }
}
</style>
<style lang="scss">
.patient-profile-dashboard {
  .viewer-container {
    .ck-content {
      min-height: 100px;
    }
  }
  .card-container {
    .viewer-container {
      padding-top: 4px !important;
    }
  }
  .apexcharts-menu-icon {
    svg {
      fill: white;
    }
  }
  .apexcharts-text {
    fill: white;
  }
}
.notes-body-modal {
  .v--modal {
    border-radius: 8px;
  }
  .close {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .notes-list {
    list-style-type: none;
  }

  .notes-container {
    max-height: calc(80vh - 30px);
    overflow: auto;
  }

  .note-container {
    border: 1px solid #c9d6e8;
    border-radius: 12px;
    background-color: white;
    position: relative;
    margin-bottom: 30px;
    width: 100%;
    min-height: 100px;
  }

  .note-info {
    color: var(--btn-text-color);
    background-color: var(--theme-color);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .note-message {
    padding-left: 12px;
    padding-right: 12px;

    .form-outer-group {
      width: 100%;

      textarea {
        border-radius: 4px;
      }
    }
  }
}

.large-body-modal {
  z-index: 1001 !important;
  .v--modal {
    border-radius: 0;
    background-color: var(--body-bg-color);
  }
  .modal-container {
    padding-bottom: 76px;
    width: 100%;
    height: 100%;
    overflow: auto;

    .flip-box {
      margin: 0 auto;
      width: 300%;
      transition: all 0.3s ease-in-out;
    }

    .close-btn {
      position: fixed;
      right: 24px;
      top: 12px;
      z-index: 1;
    }

    .large-front-body-img,
    .large-back-body-img {
      height: auto !important;
      position: static !important;
    }
  }
  .zoom-actions {
    position: fixed;
    left: 12px;
    bottom: 83px;
    display: flex;
    flex-direction: column;
    z-index: 1;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 7px;
    padding: 4px;

    .btn-transparent {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  .modal-actions {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 12px;
    text-align: center;
  }
}
</style>
